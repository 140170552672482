@import '../themes.scss';

#intro-info-container {
    height: 55vh;
    opacity: 2;
    display: flex;
    justify-content: right;
    box-shadow: 0 -0.4em var(--secondary-orange);
    background-image: url('../../images/placeholders/coffee_gradient.svg');

    //this will be used later when they find an appropriate image
    // background-image: url(../../../images/placeholders/2018BENS_DIET_011.jpg);
    // background-position: center 75%;
    // background-size: cover;
    // background-repeat: no-repeat;

    .header {
        font-size: 2.5rem;
        line-height: 1.2em;
        display: flex;
        justify-content: center;
        margin-bottom: 0.3em;
    }
}

#welcome-info {
    background-color: var(--primary-blue-opacity);
    color: white;
    height: fit-content;
    padding: 4vh;
}

#help-image-container {
    z-index: 0;
    position: relative;
    padding-left: 0;
}

#health-service-img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

#help-img {
    width: 5em;
    margin: 2em;
}

#info-container {
    height: 50vh;
    object-fit: contain;
    background-color: rgb(230, 230, 230);
    position: relative;
}

#health-service-img-big {
    width: 100%;
    height: inherit;
    object-fit: cover;
}

#help-info {
    text-align: center;
    margin: auto;
    padding: 0 7em 0 7em;
}
#health-service-desc {
    color: var(--secondary-blue);
}
