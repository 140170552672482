@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?rm5052');
    src:
        url('fonts/icomoon.eot?rm5052#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?rm5052') format('truetype'),
        url('fonts/icomoon.woff?rm5052') format('woff'),
        url('fonts/icomoon.svg?rm5052#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='contact-icon-'],
[class*='contact-icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.contact-icon-email:before {
    content: '\e100';
    color: #142e54;
}
.contact-icon-instagram:before {
    content: '\e101';
    color: #142e54;
}
.contact-icon-tiktok:before {
    content: '\e102';
    color: #142e54;
}
.contact-icon-facebook:before {
    content: '\e103';
    color: #142e54;
}
.contact-icon-youtube:before {
    content: '\e104';
    color: #142e54;
}
.contact-icon-x:before {
    content: '\e105';
    color: #142e54;
}
.contact-icon-linkedin:before {
    content: '\e106';
    color: #142e54;
}
