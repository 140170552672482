:root {
    --primary-blue: #142e54;
    --primary-white: #ffffff;
    --primary-black: #000000;
    --primary-grey: #cbcbcb;

    --secondary-blue: #122c53;
    --secondary-green: #1e9b50;
    --secondary-red: #f3492e;
    --secondary-orange: #ff9233;
    --secondary-yellow: #ffcb4b;
    --secondary-pink: #ff7378;

    --primary-blue-opacity: #145ba7e5;

    --header-font: 'omnes-pro';
    --description-font: 'forma-djr-micro';
}
